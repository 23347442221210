import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MediaService } from 'src/app/services/media.service';
import { SharedService } from 'src/app/services/shared/shared.service';
// import videojs from 'video.js';
import '../../../services/vast-parser.js'
import '../../../services/vast-parser-midroll.js'
import { HomeService } from 'src/app/services/home/home.service';
declare var videojs: any; // Declare external libraries

// import '../../../services/ads.js';
@Component({
  selector: 'app-video-ads-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoAdsPlayerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('target', { static: true }) target: ElementRef;
  @ViewChild('playBtn') playBtn: ElementRef;
  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    controls: boolean;
    from: any;
    sources: {
      src: string;
    }[];
  };
  @Input() pauseTime: number;
  @Input() currentVedio: any;
  @Input() playerValidation: boolean;
  @Input() poster: any;
  @Output() completeVideoAction: EventEmitter<any>;
  @Output() playedAction: EventEmitter<any>;
  @Output() languageChangedAction: EventEmitter<any>;
  @Output() resolutionChangeAction: EventEmitter<any>;
  @Output() playInitiation: EventEmitter<any>;
  @Output() updateAdsVedioPlayOnError:EventEmitter<any>
  @Output() pauseInitiation: EventEmitter<any>;
  @Output() dataUsageAction: EventEmitter<any>;
  @Input() isVastTag: boolean;
  @Input() vastTag: string;
  @Input() mediaId: string;
  @Input() itemType: string;  
  player: any;
  adOptions: {
    adTagUrl: string;
  };
  currentStart = 0
  currentEnd = 0
  vastTagEnabled = true;
  adDuration: any;
  @Input() midRollVastTagURL: string;
  @Input() preRollData: any;
  @Input() midRollData: any;
  @Input() adsType: any;
  
  skipDelay: number = 5;

  constructor(
    private _SharedService: SharedService,
    private _MediaService: MediaService,
    private _HomeService: HomeService
  ) {
    this.currentVedio = { src: '' };
    this.playerValidation = false;
    this.pauseTime = 0;
    this.completeVideoAction = new EventEmitter<any>();
    this.playedAction = new EventEmitter<any>();
    this.languageChangedAction = new EventEmitter<any>();
    this.resolutionChangeAction = new EventEmitter<any>();
    this.dataUsageAction = new EventEmitter<any>();
    this.adOptions = {
      adTagUrl:
        'http://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=xml_vmap1&unviewed_position_start=1&cust_params=sample_ar%3Dpremidpostpod%26deployment%3Dgmf-js&cmsid=496&vid=short_onecue&correlator=',
    };
    this.playInitiation = new EventEmitter<any>();
    this.pauseInitiation = new EventEmitter<any>();
    this.isVastTag = false;
    this.vastTag = '';
    this.playInitiation = new EventEmitter<any>();
    this.updateAdsVedioPlayOnError= new EventEmitter<any>()
    this.mediaId = '';
    this.pauseInitiation = new EventEmitter<any>();
  }
  ngOnInit(): void {
    console.log('options',this.options);
    this.player = videojs(
      this.target.nativeElement,
      {
        ...this.options,
        // techOrder: ['html5', 'vsh'],
        // html5: {
        //   hls: {
        //     withCredentials: true // Allow sending cookies with cross-origin requests
        //   }
        // },
        controlBar: {
          pictureInPictureToggle: false,
          progressControl: {
            seekBar: false
          },
          // fullscreenToggle: false 
        },
      },
      () => {
        // console.log('onPlayerReady', this);
      }
    );
    this.player.aspectRatio('16:9');
    this.player.responsive(true);
    if (this.options.autoplay) {
      this.player.play();
    }
    this.player.poster(this.poster);
    // this.player.controls(this.options.controls);
    this.pauseTime && this.player.currentTime(this.pauseTime);
    this.player.on('error', (error: any) => {
      this._SharedService.updateLoader(true);
      this.resolutionChangeAction.emit(error);
      this._SharedService.updateLoader(false);
      // console.log(error);
    });
    this._MediaService.getVideoPlayerStatus().subscribe((data) => {
      if (!data) {
        this.player.pause();
      }
    });

   this.player.on('loadedmetadata',(data: any)=>{
    // console.log(data,'loadedmetadata')
   })
   
    this.skipCreate();
  }
  
  // ngAfterViewInit() {
  //   this.autoplay();
  //   this.playBtn.nativeElement.click();

  //   if(this.player){
  //     // this.playBtn.nativeElement.click();
  //     setTimeout(()=>{
  //       // this.playBtn.nativeElement.click();
  //       console.log('player, wokring',this.player)
  //       // this.player.play();
  //       // this.playBtn.nativeElement.click();
  //       // this.autoplay();
  //       let element: HTMLElement = document.getElementById(
  //         "playBtn"
  //       ) as HTMLElement;
  //       element.click();
  //     },5000);
  //   }
  // }
  // autoplay(){
  //     this.player.play();
  // }
  
  ngOnChanges(SimpleChanges: any): void {
    console.log('21asdfsdfction', this.player, this.options)
    if (this.player && SimpleChanges.mediaId) {
      this.player.poster(this.poster);
      // this.player.controls(this.options.controls);
      this.player.currentTime(0);
      if (this.options.autoplay) {
        this.player.play();
      }      
    }

    if(this.adsType === 'preroll'){
      this.skipDelay = this.preRollData ? this.preRollData?.creatives[0]?.skipDelay : 5;
    }
    else if(this.adsType === 'midroll'){
      this.skipDelay = this.midRollData ? this.midRollData?.creatives[0]?.skipDelay : 5;
    }
  }

 skipCreate(){
  const skipButton = window.document.createElement('div');
    skipButton.className = 'vast-skip-button disabled ';
    skipButton.id = 'skip-btn';
    skipButton.style.display = 'block';
    skipButton.innerText = this.player.localize('');
    this.player.el().appendChild(skipButton);
    skipButton.onclick= ()=> {
      // this._HomeService.updateSkipState(true);
      this.adDuration && this.player.currentTime(this.adDuration - 1);
      this.skipAdsAPI();
    }

    let clickThroughUrl : any;
    if(this.adsType === 'preroll'){
      clickThroughUrl = this.preRollData?.creatives[0]?.videoClickThroughURLTemplate ? this.preRollData?.creatives[0]?.videoClickThroughURLTemplate.url : '';
    }
    else if(this.adsType === 'midroll'){
      clickThroughUrl = this.midRollData?.creatives[0]?.videoClickThroughURLTemplate ? this.midRollData?.creatives[0]?.videoClickThroughURLTemplate.url : '';
    }

    if(clickThroughUrl){
      const clickButton = window.document.createElement('div');
      clickButton.className = 'vast-click-button';
      clickButton.style.display = 'block';
      clickButton.innerText = this.player.localize('More Info');
      clickButton.onclick = () => {
          if(clickThroughUrl){
            window.open(clickThroughUrl,"_blank");
          }
      };
      this.player.el().appendChild(clickButton);
    }
  }

  handleError(event: any) {
    this.updateAdsVedioPlayOnError.emit(event);
  }
  playerAction(event: any) {
    this.skipButton();
    // console.log('action', event)
    this.playedAction.emit(event);
    this.adDuration = event.target.duration ? event.target.duration : 0;
    if(this.player.textTracks().length>0){
      if(this.player.textTracks()[0].activeCues[0].value){
        let newStart = this.player.textTracks()[0].activeCues[0].value.start
        let newEnd = this.player.textTracks()[0].activeCues[0].value.end
        this.checkNewChunkLoaded(newStart, newEnd);
      } 
    }
  }

  skipButton(){
    let skipDelay= this.skipDelay;
    let btnId : any = document.querySelector('#skip-btn');
    if(skipDelay && skipDelay !== -1){
      const timeLeft = Math.ceil(skipDelay - this.player.currentTime());
          if (timeLeft > 0) {
            btnId.innerHTML =
                  timeLeft + ' ' + this.player.localize(' Skip Seconds');
          } else if (
              (' ' + btnId.className + ' ').indexOf(' enabled ') === -1
          ) {
            btnId.classList.remove('disabled')
            btnId.className += ' enabled ';
            btnId.innerHTML = this.player.localize('Skip ');
          }
    }
    else{
      btnId.classList.add('d-none')
    }
  }

  playInitiate(event: any): void {
    if (event) {
      this.playInitiation.emit(event);
    }
  }
  pauseInitate(event: any): void{
    if(event){
      this.pauseInitiation.emit(event);
    }
  }
  ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  checkNewChunkLoaded(newStart: number, newEnd: number): void {
    // Check if the new start and end values differ from the previous ones
    if (newStart !== this.currentStart || newEnd !== this.currentEnd) {
          // Update current values
          this.currentStart = newStart;
          this.currentEnd = newEnd;
          this.dataUsageAction.emit({
            byteLength : this.player.textTracks()[0].activeCues[0].value.byteLength
          })
      }
  }

  skipAdsAPI(){
    let skipUrl;
    if(this.adsType === 'preroll'){
      skipUrl = this.preRollData ? this.preRollData?.creatives[0]?.trackingEvents?.skip[0] : '';
      this.getTrackingEventAPI(skipUrl);
    }
    else if(this.adsType === 'midroll'){
      skipUrl = this.midRollData ? this.midRollData?.creatives[0]?.trackingEvents?.skip[0] : '';
      this.getTrackingEventAPI(skipUrl);
    }
  }

  getTrackingEventAPI(eventUrl:any){
    fetch(eventUrl)
    .then(response => response)
    .catch(err => err.message);
  }

}
